import StoreItemsModule from "@/config/store/StoreItemsModule"

const BASE_URI = "/accounts/roles"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch", "create", "update", "delete"]
})

export default store
