import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import { filter, map, get } from "lodash-es"

import extractActions from "./actions"
import extractMutations from "./mutations"

const BASE_URI = "/accounts/users"
export const mapFilters = filters => {
  return {
    shop_ids: map(filters.shops, "id"),
    role_id: get(filters, "role.id")
  }
}

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch", "update"],
  withFilters: {
    shops: [],
    role: null
  },
  withPagination: true,
  withSorting: {
    field: "full_name",
    type: "asc"
  },
  mapFilters
})

store.mergeState({
  item: {}
})

store.mergeGetters({
  syncedItems: ({ items }) => filter(items, "sync")
})

const mutations = extractMutations()
store.mergeMutations(mutations)

const { CHECK_ACCOUNT_ID_EXISTS, FETCH_ITEM, CREATE_ITEM, DELETE_ITEM, UPDATE_ITEM } = extractActions({
  baseURI: BASE_URI
})
store.mergeActions({ CHECK_ACCOUNT_ID_EXISTS })
store.mergeActions({ FETCH_ITEM, DELETE_ITEM, CREATE_ITEM, UPDATE_ITEM }, withLoading)

export default store
